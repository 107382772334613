<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("schools.title") }}
          </template>
          <template v-slot:body>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th class="text-left">{{ $t("schools.list.nameRu") }}</th>
                <th class="text-left">{{ $t("schools.list.nameKg") }}</th>
                <th class="text-left">{{ $t("schools.list.address") }}</th>
                <th class="text-left">
                  {{ $t("schools.list.contactPhone") }}
                </th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in schools" :key="item.id">
                <td>{{ item.nameRu }}</td>
                <td>{{ item.nameKg }}</td>
                <td>{{ item.address }}</td>
                <td>{{ item.contactPhone }}</td>
                <td>
                  <b-button @click="editSchool(item)" variant="primary">
                    {{ $t("common.edit") }}
                  </b-button>
<!--                  <b-button @click="importStudents()" variant="primary">
                    {{ $t("common.importStudents") }}
                  </b-button>
                  <b-button @click="importTeachers()" variant="primary">
                    {{ $t("common.importTeachers") }}
                  </b-button>        -->
                  <b-button @click="showChangeImageModal()" variant="primary">
                    {{ $t("schools.list.image") }}
                  </b-button>    
                  <b-button @click="showChangeIconModal()" variant="primary">
                      Иконка
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
            <div>
              <b-modal id="modal-1" ref="school-modal">
                <b-form v-if="show">
                  <b-form-group id="input-group-1" label-for="input-1">
                    <template v-slot:label>
                      {{ $t("schools.list.nameRu") }}
                    </template>
                    <b-form-input
                      id="input-1"
                      v-model="form.nameRu"
                      type="email"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-2" label-for="input-2">
                    <template v-slot:label>
                      {{ $t("schools.list.nameKg") }}
                    </template>
                    <b-form-input
                      id="input-2"
                      v-model="form.nameKg"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-3" label-for="input-3">
                    <template v-slot:label>
                      {{ $t("schools.list.address") }}
                    </template>
                    <b-form-input
                      id="input-3"
                      v-model="form.address"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group id="input-group-4" label-for="input-4">
                    <template v-slot:label>
                      {{ $t("schools.list.contactPhone") }}
                    </template>
                    <b-form-input
                      id="input-4"
                      v-model="form.contactPhone"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group id="input-group-4" label-for="input-4">
                    <template v-slot:label>
                      {{ $t("schools.list.latitude") }}
                    </template>
                    <b-form-input
                        id="input-4"
                        v-model="form.latitude"
                        required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group id="input-group-4" label-for="input-4">
                    <template v-slot:label>
                      {{ $t("schools.list.longitude") }}
                    </template>
                    <b-form-input
                        id="input-4"
                        v-model="form.longitude"
                        required
                    ></b-form-input>
                  </b-form-group>
                  <h1>Статистика</h1>
                  <b-form-group id="input-group-4" label-for="input-4">
                    <template v-slot:label>
                      {{ $t("schools.list.foundYear") }}
                    </template>
                    <b-form-datepicker
                        id="input-4"
                        v-model="form.foundYear"

                    ></b-form-datepicker>
                  </b-form-group>
                  <b-form-group id="input-group-4" label-for="input-4">
                    <template v-slot:label>
                      {{ $t("schools.list.studentsCount") }}
                    </template>
                    <b-form-input
                        id="input-4"
                        v-model="form.studentsCount"
                        required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group id="input-group-4" label-for="input-4">
                    <template v-slot:label>
                      {{ $t("schools.list.graduatesPerYear") }}
                    </template>
                    <b-form-input
                        id="input-4"
                        v-model="form.graduatesPerYear"
                        required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group id="input-group-4" label-for="input-4">
                    <template v-slot:label>
                      {{ $t("schools.list.booksInTheLibrary") }}
                    </template>
                    <b-form-input
                        id="input-4"
                        v-model="form.booksInTheLibrary"
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="onSubmit" variant="primary">{{
                    $t("common.save")
                  }}</b-button>
                  <b-button type="reset" @click="onReset" variant="danger">{{
                    $t("common.cancel")
                  }}</b-button>
                </template>
              </b-modal>

              <b-modal id="modal-import-students" ref="import-students-modal">
                <b-form v-if="show">
                  <b-form-group id="input-group-1" label-for="input-1">
                    <template v-slot:label>
                      {{ $t("common.importStudents") }}
                    </template>
                    <input type="file" @change="uploadFile" ref="file">
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="onImportStudents" variant="primary">{{
                      $t("common.save")
                    }}</b-button>
                  <b-button type="reset" @click="onResetImportStudents" variant="danger">{{
                      $t("common.cancel")
                    }}</b-button>
                </template>
              </b-modal>
              
              <b-modal id="modal-import-teachers" ref="teachers-import-modal">
                <b-form v-if="show">
                  <b-form-group id="input-group-1" label-for="input-1">
                    <template v-slot:label>
                      {{ $t("common.importTeachers") }}
                    </template>
                    <input type="file" @change="uploadFile" ref="file">
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="onImportTeachers" variant="primary">{{
                      $t("common.save")
                    }}</b-button>
                  <b-button type="reset" @click="onResetImportTeachers" variant="danger">{{
                      $t("common.cancel")
                    }}</b-button>
                </template>
              </b-modal>
              
              <b-modal id="modal-change-school-image" ref="change-school-image-modal">
                <b-form v-if="show">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("schools.list.image") }}
                    </template>
                    <input type="file" @change="uploadFile" ref="file">
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="saveChangeSchoolImage" variant="primary">{{
                      $t("common.save")
                    }}</b-button>
                  <b-button type="reset" @click="onResetChangeSchoolImage" variant="danger">{{
                      $t("common.cancel")
                    }}</b-button>
                </template>
              </b-modal>
              
              <b-modal id="modal-change-school-icon" ref="change-school-icon-modal">
                <b-form v-if="show">
                  <b-form-group>
                    <template v-slot:label>
                      Иконка
                    </template>
                    <input type="file" @change="uploadIconFile" ref="file">
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="saveChangeSchoolIcon" variant="primary">{{
                      $t("common.save")
                    }}</b-button>
                  <b-button type="reset" @click="onResetChangeSchoolIcon" variant="danger">{{
                      $t("common.cancel")
                    }}</b-button>
                </template>
              </b-modal>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            Детали
          </template>
          <template v-slot:body>
            <b-card no-body>
              <b-tabs pills card >

                <b-tab>
                  <template v-slot:title>
                    Расписание звонков 
                  </template>
                  <b-tabs pills card>
                    <b-tab>
                      <template v-slot:title>
                        По сменам
                      </template>
                      <div class="row">
                        <div class="col-lg-3" v-for="callScheduleItem in callSchedule" :key="callScheduleItem.type">
                          <h6>
                            {{ $t("callSchedule.types." + callScheduleItem.type) }}                    
                          </h6>
                          <table class="table table-bordered">
                            <thead class="thead-light">
                            <tr>
                              <th>#</th>
                              <th>начало</th>
                              <th>конец</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="subjectNumber in subjectNumbers" :key="subjectNumber">
                              <td>{{subjectNumber}}</td>
                              <td>
                                <b-form-timepicker v-model="callScheduleItem['items'][subjectNumber]['start']"
                                                   hide-header
                                                   @change="callScheduleChanged"
                                                   locale="ru"></b-form-timepicker>
                              </td>
                              <td>
                                <b-form-timepicker v-model="callScheduleItem['items'][subjectNumber]['end']"
                                                   locale="ru"></b-form-timepicker>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="row">
                        <button class="btn btn-success"
                                v-if="callScheduleSaveButtonShow"
                                @click="saveCallSchedule">Сохранить расписание звонков</button>
                      </div>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                       Дополнительно
                      </template>
                      <div>
                        <CallScheduleAdditional></CallScheduleAdditional>
                      </div>
                    </b-tab>
                  </b-tabs>                  
                </b-tab>
<!--                <b-tab>
                  <template v-slot:title>
                    Общие данные
                  </template>
                  <div>
                  </div>
                </b-tab>-->
                <b-tab>
                  <template v-slot:title>
                    Настройки
                  </template>
                  <div>
                    <b-form>
                      <table class="table table-bordered">
                        <tbody>
                        <tr>
                          <td>Есть третья смена</td>
                          <td>
                            <b-form-group>
                              <template v-slot:label>

                              </template>
                              <b-form-checkbox
                                  v-model="settings.hasThirdShift"
                              ></b-form-checkbox>
                            </b-form-group>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Количество дней в течении которых можно менять оценки
                          </td>
                          <td>
                            <b-form-group>
                              <b-form-input
                                  v-model="settings.marksChangeAllowedDays"
                              ></b-form-input>
                              <template v-slot:label>
                              </template>
                            </b-form-group>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Разрешить классным руководителям менять данные учеников
                          </td>
                          <td>
                            <b-form-group>
                              <template v-slot:label>
                              </template>
                              <b-form-checkbox
                                  v-model="settings.allowClassroomTeacherChangeStudentData"
                              ></b-form-checkbox>
                            </b-form-group>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Разрешить классным руководителям менять расписание класса
                          </td>
                          <td>
                            <b-form-group>
                              <template v-slot:label>
                              </template>
                              <b-form-checkbox
                                  v-model="settings.allowClassroomTeacherChangeTimeTable"
                              ></b-form-checkbox>
                            </b-form-group>
                          </td>
                        </tr>       
                        <tr>
                          <td>
                            Разрешить классным руководителям удалять уроки с расписания
                          </td>
                          <td>
                            <b-form-group>
                              <template v-slot:label>
                              </template>
                              <b-form-checkbox
                                  v-model="settings.allowClassroomTeacherDeleteLessons"
                              ></b-form-checkbox>
                            </b-form-group>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Разрешить классным руководителям менять предметы
                          </td>
                          <td>
                            <b-form-group>
                              <template v-slot:label>
                              </template>
                              <b-form-checkbox
                                  v-model="settings.allowClassroomTeacherChangeSubject"
                              ></b-form-checkbox>
                            </b-form-group>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Разрешить не ставить оценки
                          </td>
                          <td>
                            <b-form-group>
                              <template v-slot:label>
                              </template>
                              <b-form-checkbox
                                  v-model="settings.allowNoRatings"
                              ></b-form-checkbox>
                            </b-form-group>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Разрешить не писать тему уроков
                          </td>
                          <td>
                            <b-form-group>
                              <template v-slot:label>
                              </template>
                              <b-form-checkbox
                                  v-model="settings.allowNoWriteLessonContent"
                              ></b-form-checkbox>
                            </b-form-group>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Разрешить не писать домашнее задание
                          </td>
                          <td>
                            <b-form-group>
                              <template v-slot:label>
                              </template>
                              <b-form-checkbox
                                  v-model="settings.allowNoWriteHomeWork"
                              ></b-form-checkbox>
                            </b-form-group>
                          </td>
                        </tr>  
                        <tr>
                          <td>
                            Миниммальное количество оценок за день
                          </td>
                          <td>
                            <b-form-group>
                              <template v-slot:label>
                              </template>
                              <b-form-input
                                  v-model="settings.minMarksAmountToUnlockNextLesson"
                              ></b-form-input>
                            </b-form-group>
                          </td>
                        </tr>     
                        <tr>
                          <td>
                            Разрешить наперед писать темы
                          </td>
                          <td>
                            <b-form-group>
                              <template v-slot:label>
                              </template>
                              <b-form-checkbox
                                  v-model="settings.allowTopicsFilledInAdvance"
                              ></b-form-checkbox>
                            </b-form-group>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Язык обучения
                          </td>
                          <td>
                            
                            <table>
                              <tr v-for="lang in languageOfInstructions" :key="lang.value">
                                <td>
                                  <b-form-group>
                                    <template v-slot:label>
                                    </template>
                                    <b-form-checkbox
                                        v-model="schoolLanguageOfInstruction[lang.value]"
                                    ></b-form-checkbox>
                                  </b-form-group>
                                </td>
                                <td>
                                  {{lang.text}}
                                </td>
                              </tr>
                            </table>

                          </td>
                        </tr>
                        <tr>
                          <td>
                            Количество рабочих дней
                          </td>
                          <td>
                            <b-form-group>
                              <template v-slot:label>
                              </template>
                              <b-form-select
                                  v-model="settings.workingDaysCount"
                                  :options="workingDaysCountOptions"
                              ></b-form-select>
                            </b-form-group>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Максимальное количество уроков за смену 
                          </td>
                          <td>
                            <b-form-group>
                              <template v-slot:label>
                              </template>
                              <b-form-select
                                  v-model="settings.maxLessonsCountPerDay"
                                  :options="lessonsPerDayOptions"
                              ></b-form-select>
                            </b-form-group>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </b-form>
                  </div>
                  <div class="row">
                    <button class="btn btn-success"
                            @click="saveSettings">Сохранить настройки</button>
                  </div>
                </b-tab>
                <b-tab>
                  <template v-slot:title>
                    Рабочие и нерабочие дни
                  </template>
                  <div>
                    <WorkingDays></WorkingDays>
                  </div>
                </b-tab>
              </b-tabs>
            </b-card>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>


<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import CallScheduleAdditional from "./partials/CallScheduleAdditional.vue";
import WorkingDays from "./partials/WorkingDays.vue";

export default {
  name: "schoolAdminDashboard",
  components: {
    KTPortlet,
    CallScheduleAdditional,
    WorkingDays,
  },
  data() {
    return {
      schools: null,
      filtes: null,
      iconFile: null,
      dialog: true,
      form: {
        nameRu: "",
        nameKg: "",
        address: "",
        contactPhone: ""
      },
      show: true,
      subjectNumbers: [0, 1, 2, 3, 4, 5 , 6, 7, 8],
      workingDaysCountOptions: [5 , 6],
      lessonsPerDayOptions: [4, 5 , 6, 7],
      
      /**/
      callSchedule:[],
      languageOfInstructions: [],
      callScheduleSaveButtonShow: true,
      value: '',
      settings: {
        marksChangeAllowedDays: 0,
        hasThirdShift: false,
        allowClassroomTeacherChangeStudentData: false,
        allowClassroomTeacherChangeTimeTable: false,
        allowClassroomTeacherDeleteLessons: false,
        allowClassroomTeacherChangeSubject: false,
        allowNoRatings: false,
        allowNoWriteLessonContent: false,
        allowNoWriteHomeWork: false,
        minMarksAmountToUnlockNextLesson: 1,
        workingDaysCount: 5,
        maxLessonsCountPerDay: 6,
        languageOfInstruction:[]
      },
      schoolLanguageOfInstruction:{}
    };
  },
  mounted() {
    this.reloadData();
    this.loadCallSchedule();
    this.loadSettings();
  },
  methods: {
    uploadFile() {
      this.filtes = this.$refs.file.files[0];
    },    
    uploadIconFile() {
      this.iconFile = this.$refs.file.files[0];
    },
    importStudents() {

      this.$refs["import-students-modal"].show(); 
    },
    onImportStudents(evt) {
      evt.preventDefault();
      let $this = this;
      ApiService.importSecured("import/importStudent", this.filtes)
          .then(function() {
            $this.$refs["import-students-modal"].hide();
          })
          .catch(({ response }) => {
            console.log(response);
            $this.reloadData();
          });
    },
    onResetImportStudents(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["import-students-modal"].hide();
    },
    importTeachers() {

      this.$refs["teachers-import-modal"].show();
    },
    onImportTeachers(evt) {
      evt.preventDefault();
      let $this = this;
      ApiService.importSecured("import/importTeachers", this.filtes)
          .then(function() {
            $this.$refs["teachers-import-modal"].hide();
          })
          .catch(({ response }) => {
            console.log(response);
            $this.reloadData();
          });
    },
    onResetImportTeachers(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["teachers-import-modal"].hide();
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["school-modal"].hide();
    },
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;
      ApiService.postSecured("schoolAdmin/save", this.form)
          .then(function() {
            $this.$refs["school-modal"].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({ response }) => {
            console.log(response);
            $this.reloadData();
          });
    },
    reloadData() {
      ApiService.querySecured("schoolAdmin/schools")
          .then(({ data }) => {
            this.schools = data.list;
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    cleanModel() {
      this.form = {};
    },
    editSchool(school) {
      this.form = school;
      this.$refs["school-modal"].show();
    },
    /*image*/
    showChangeImageModal() {

      this.$refs["change-school-image-modal"].show();
    },
    saveChangeSchoolImage(evt) {
      evt.preventDefault();
      let $this = this;
      ApiService.importSecured("schoolAdmin/changeImage", this.filtes)
          .then(function() {
            $this.$refs["change-school-image-modal"].hide();
          })
          .catch(({ response }) => {
            console.log(response);
            $this.reloadData();
          });
    },
    onResetChangeSchoolImage(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["change-school-icon-modal"].hide();
    },

    /*icon*/
    showChangeIconModal() {

      this.$refs["change-school-icon-modal"].show();
    },
    saveChangeSchoolIcon(evt) {
      evt.preventDefault();
      let $this = this;
      ApiService.importSecured("schoolAdmin/changeIcon", this.iconFile)
          .then(function() {
            $this.$refs["change-school-icon-modal"].hide();
          })
          .catch(({ response }) => {
            console.log(response);
            $this.reloadData();
          });
    },
    onResetChangeSchoolIcon(evt) {
      evt.preventDefault();
      this.iconFile = null;
      this.$refs["change-school-icon-modal"].hide();
    },
    
    /*CallSchedule*/
    callScheduleChanged(){
      this.callScheduleSaveButtonShow = true;
    },
    loadCallSchedule(){
      let $this = this;
      $this.callSchedule = [];
      ApiService.querySecured("callSchedule/getForSchool")
          .then(({ data }) => {
            let dd = {};
            for (let dataIndex in data){
              let dataItem = data[dataIndex];
              dd[dataItem.type + "-" + dataItem.number] = dataItem;
            }

            $this.callSchedule.push({
              type: 'first',
              items:[]
            });

            $this.callSchedule.push({
              type: 'second',
              items:[]
            });
            
            $this.callSchedule.push({
              type: 'third',
              items:[]
            });

            for(let i in this.callSchedule){
              let item = this.callSchedule[i];
              for (let ii in this.subjectNumbers) {
                let index = this.subjectNumbers[ii];
                let key = item.type + "-" + index;
                if(typeof dd[key] != "undefined"){
                  item['items'][index] = dd[key];
                }else{
                  item['items'][index] = {
                    'type':item.type,
                    'number':index,
                    'start': null,
                    'end': null
                  };
                }
              }
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });   
    },
    saveCallSchedule(){
      let $this = this;
      let data = [];    
      
      for(let i in this.callSchedule){
        let shItems = this.callSchedule[i]['items'];
        for (let ii in shItems){
          let shItem = shItems[ii];
          data.push(shItem);
        }
      }
      
      ApiService.postSecured("callSchedule/save", {items: data})
          .then(function() {
            $this.loadCallSchedule();
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    /*endCallSchedule*/
    
    /*settings*/
    loadSettings(){
      let $this = this;

      ApiService.querySecured("schoolAdmin/getSettings")
          .then(({ data }) => {
            $this.settings = data;
            
            ApiService.querySecured("dictionaries/languageOfInstruction")
                .then(({data}) => {
                  $this.languageOfInstructions = data.dictionary;
                }).catch(({response}) => {
              console.log(response);
            });
            
          })
          .catch(({ response }) => {
            console.log(response);
          });
      
      
      
    },
    saveSettings(){
      let $this = this;
      
      ApiService.postSecured("schoolAdmin/saveSettings", this.settings)
          .then(function() {
            $this.loadSettings();
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    /*end settings*/
  }
};
</script>
