<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="pb-4 pull-right">
        <b-button @click="create" class="btn btn-success">{{ $t("common.new") }}</b-button>
      </div>
    </div>
    <div class="col-lg-3" v-for="callSchedulesItem1 in callSchedules" :key="callSchedulesItem1.id">
      <div style="position: relative; height: 50px; padding: 10px 0 0 10px">
        <b>{{callSchedulesItem1.name}}</b> 
        <button class="btn btn-icon btn-success" style="position: absolute; right: 5px" 
                                            @click="edit(callSchedulesItem1)"><i class="fa fa-pen"></i></button>
      </div>
      <table class="table table-bordered">
        <thead class="thead-light">
        <tr>
          <th>#</th>
          <th>начало</th>
          <th>конец</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="subjectNumber in subjectNumbers" :key="subjectNumber">
          <td>{{subjectNumber}}</td>
          <td>
            {{callSchedulesItem1['items'][subjectNumber]['start']}}
          </td>
          <td>
            {{callSchedulesItem1['items'][subjectNumber]['end']}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <b-modal ref="additional-schedule-add-modal">
      <b-form-group id="input-group-4" label-for="input-4">
        <template v-slot:label>
          Название
        </template>
        <b-form-input
            id="input-4"
            v-model="callSchedule.name"
            required
        ></b-form-input>
      </b-form-group>
      <table class="table table-bordered">
        <thead class="thead-light">
        <tr>
          <th>#</th>
          <th>начало</th>
          <th>конец</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="subjectNumber in subjectNumbers" :key="subjectNumber">
          <td>{{subjectNumber}}</td>
          <td>
            <b-form-timepicker v-model="callScheduleItem[subjectNumber]['start']"
                               hide-header
                               locale="ru"></b-form-timepicker>
          </td>
          <td>
            <b-form-timepicker v-model="callScheduleItem[subjectNumber]['end']"
                               locale="ru"></b-form-timepicker>
          </td>
        </tr>
        </tbody>
      </table>
      <template v-slot:modal-footer>
        <b-button type="submit" @click="saveChanges" variant="primary">{{
            $t("common.save")
          }}</b-button>
        <b-button type="reset" @click="closeModal" variant="danger">{{
            $t("common.cancel")
          }}</b-button>
      </template>
    </b-modal>
    
    
  </div>
</template>
<style>
</style>
<script>
import ApiService from "@/common/api.service";
export default {
  name: "CallScheduleAdditional",
  components: {
    
  },
  data() {
    return {
      list:[],
      callSchedules:[],
      
      callScheduleItem: {},
      callSchedule:{
        name:""
      },
      subjectNumbers: [],
    };
  },
  mounted() {
    this.subjectNumbers = [0, 1, 2, 3, 4, 5 , 6, 7, 8];
    for (let ii in this.subjectNumbers) {
      let index = this.subjectNumbers[ii];
      this.callScheduleItem[index] = {
        'number':index,
        'start': null,
        'end': null
      };
    }
    
    this.loadCallSchedule();
  },
  methods: {
    create(){
      this.callSchedule = {
        name:"",
        id:0
      };
      
      for (let ii in this.subjectNumbers) {
        let index = this.subjectNumbers[ii];
        this.callScheduleItem[index] = {
          'number':index,
          'start': null,
          'end': null
        };
      }
      
      this.$refs["additional-schedule-add-modal"].show();
    },
    edit(cs){
      this.callSchedule = {
        name: cs.name,
        id: cs.id,
      };
      
      this.callScheduleItem = cs.items;
      
      this.$refs["additional-schedule-add-modal"].show();
    }, 
    closeModal(){
      this.$refs["additional-schedule-add-modal"].hide();
    },
    saveChanges(){

      let $this = this;
      let data = [];

      for(let i in this.callScheduleItem){
        let shItems = this.callScheduleItem[i];
        data.push(shItems);
      }
      let aa = {
        id:this.callSchedule.id,
        name:this.callSchedule.name,
      }

      aa['items'] = data;

      ApiService.postSecured("callSchedule/saveAdditional", aa)
          .then(function() {
            $this.loadCallSchedule();
            $this.$refs["additional-schedule-add-modal"].hide();
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    loadCallSchedule(){
      let $this = this;
      $this.callSchedules = [];
      ApiService.querySecured("callSchedule/getForSchoolAdditional")
          .then(({ data }) => {
                       
            for (let dataIndex in data){
              let dataItem = data[dataIndex];
              let item = {
                id: dataItem.id,
                name:dataItem.name
              };
              
              let dd = {};
              for (let iii in dataItem.items){
                let lll =  dataItem.items[iii];

                dd[lll.number] = lll;
              }
              
              let callItems = {};
              for (let ii in this.subjectNumbers) {
                let index = this.subjectNumbers[ii];

                if(typeof dd[index] != "undefined"){
                  callItems[index] = dd[index];
                }else{
                  callItems[index] = {
                    'number':index,
                    'start': null,
                    'end': null
                  };
                }
              }
              item['items'] = callItems;
              console.log(item);
              $this.callSchedules.push(item);
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
  }
};
</script>
