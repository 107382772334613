<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="pb-4 pull-right">
        <b-button @click="create" class="btn btn-success">{{ $t("common.new") }}</b-button>
      </div>
    </div>
    <div>
      <table class="table table-bordered">
        <thead class="thead-light">
        <tr>
          <th>Дата</th>
          <th>Тип</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in days" :key="item.id">
          <td>{{item.dateString}}</td>
          <td>{{showType(item.type)}}</td>
          <td>
            <button class="btn btn btn-icon btn-success btn-danger" @click="deleteItem(item)" style="margin-left: 10px">
              <i class="flaticon2-trash"></i>
            </button>
          </td>
        </tr>
        
        </tbody>
      </table>
    </div>
    <b-modal ref="working-days-add-modal">
      <b-form-group>
        <template v-slot:label>
          Название
        </template>
        <b-form-datepicker
            v-model="form.date"
            locale="ru"
            start-weekday="1"
        ></b-form-datepicker>
      </b-form-group>
      <b-form-group>
        <template v-slot:label>
          Тип
        </template>
        <b-form-select
            v-model="form.type"
            :options="dayTypeOptions"
        ></b-form-select>
      </b-form-group>
      <template v-slot:modal-footer>
        <b-button type="submit" @click="saveChanges" variant="primary">{{
            $t("common.save")
          }}</b-button>
        <b-button type="reset" @click="closeModal" variant="danger">{{
            $t("common.cancel")
          }}</b-button>
      </template>
    </b-modal>
    
    
  </div>
</template>
<style>
</style>
<script>
import ApiService from "@/common/api.service";
export default {
  name: "WorkingDays",
  components: {
    
  },
  data() {
    return {
      dayTypeOptions:[{text: "Рабочий день", value: 'workday'}, { text: "Не рабочий день", value: 'nonworkday' }],
      dayTypeOptionsNames:{
        "workday": "Рабочий день",
        "nonworkday": "Не рабочий день"
      },
      form:{
        date:null,
        type:""
      },
      days:[]
    };
  },
  mounted() {
    this.reload();
  },
  methods: {
    create(){
      this.form = {};
      
      this.$refs["working-days-add-modal"].show();
    },
    edit(cs){
      this.form = {
        date: cs.date,
        id: cs.id,
        type: cs.type,
        schoolId: cs.schoolId,
      };
      
      this.$refs["working-days-add-modal"].show();
    }, 
    closeModal(){
      this.$refs["working-days-add-modal"].hide();
    },
    saveChanges(){
      let $this = this;
      
      ApiService.postSecured("schoolAdmin/saveNonWorkingDayForSchool", this.form)
          .then(function() {
            $this.reload();
            $this.closeModal();
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    reload(){
      let $this = this;
      $this.days = [];
      ApiService.querySecured("schoolAdmin/getNonWorkingDayForSchool")
          .then(({ data }) => {
            $this.days = data;
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    showType(type){
      return this.dayTypeOptionsNames[type];
    },
    deleteItem(item){
      if(!confirm("Удалить?")){
        return;
      }
      
      let $this = this;
      ApiService.postSecured("schoolAdmin/deleteNonWorkingDayForSchool", {id:item.id})
          .then(() => {
            $this.reload();
          })
          .catch(({ response }) => {
            console.log(response);
          });
    }
  }
};
</script>
